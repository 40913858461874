<template>
  <div>
    <vs-row class="p-0">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12"> 
            <vx-card class="mr-4 p-0">
                 <label class="vs-input--label">Cliente:</label>
                 <v-select  
                    label="nome"                                                                 
                    :options="listaClientes"  
                    :filter="pesquisarCliente"
                    v-model="clienteSelecionado"      
                    @input="gerarRelatorio(false)"                                  
                    class="w-full vs-input--label">
                    <template v-slot:option="option">                        
                        <span style='font-family: Verdana, sans-serif;'> {{ option.CNPJCPF }} </span> - {{ option.nome }}  - {{ option.razaoSocial }}
                    </template>
                </v-select>  
                <vs-button class="ml-1 mt-2" icon="table_chart" color="success" @click="gerarTodosRelatorio" v-if="isExibirGerarTodos">Gerar todos clientes</vs-button>                                
            </vx-card>
        </vs-col>
    </vs-row> 

    
    
    <ViewRelatorio v-if="dadosPDF" :sourcePDF="dadosPDF" class="mt-2"
        @exportarPDF="exportarRelatorio('PDF')" @exportarXLS="exportarRelatorio('XLS')" 
        @exportarTXT="exportarRelatorio('TXT')" @exportarRTF="exportarRelatorio('RTF')" />  

  </div>
</template>

<script>
import axios from "@/axios.js"
import ViewRelatorio from '@/views/components/ViewRelatorioJasper'

export default {
    components: {
        ViewRelatorio
    },
    created() {
        
    },
    data() {
        return {
            exibirMensagemNenhumDadoEncontrado: false,
            listaClientes: [],
            clienteSelecionado: null,     
            dadosPDF: null,              
            publicPath: process.env.BASE_URL
        }
    },
    computed: {
        isExibirGerarTodos() {
            if ( this.$store.state.AppActiveUser.idEmpresa === 1) {
                return false;
            }

            return true;
        }
    },
    methods: {
        
        pesquisarCliente(options, varTermoPesquisa) {
            varTermoPesquisa = varTermoPesquisa.toUpperCase();
            return options.filter(function (c) {
                    return (c.nome.toUpperCase().indexOf(varTermoPesquisa) != -1  || c.razaoSocial.toUpperCase().indexOf(varTermoPesquisa) != -1 || 
                    c.CNPJCPF.toUpperCase().indexOf(varTermoPesquisa) != -1);
            })
        },
        
        fetchListaClientes() {     
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('method', 'FindAll');
            params.append('orderDirection', 'ASC');
            params.append('outputFormat', 'JSON');
            params.append('naoExibirInativos', 'true');

            if (this.$store.state.AppActiveUser.perfil === "MONITOR_PANICO" || this.$store.state.AppActiveUser.perfil === "EMPRESA_MONITOR_PANICO") {
                params.append('monitoravelPorCentral', 'true');
            }
            
            axios.post("/ClientesRest", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.listaClientes = response.data;         
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
            })    
                                
        }, 
        exportarRelatorio(pTipoFormato) {
            this.$vs.loading();
            
            const params = new URLSearchParams();            
            params.append('outputFormat', 'JSON');
            if (this.clienteSelecionado && this.clienteSelecionado.id) {
                params.append('pIdCliente', this.clienteSelecionado.id);
            }
            params.append('nomeRelatorio', "RelatorioMensalidadeClientes");
            params.append('exportarTipo', pTipoFormato);

            axios.post("/RelatoriosJasper", params, {              
                        credentials: 'include', responseEncoding: 'iso88591', withCredentials: true,  responseType: 'arraybuffer' } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {                
                    this.$router.push({ path: '/login' });
                } else {                                                
                    
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    if (this.clienteSelecionado && this.clienteSelecionado.id) {
                        fileLink.setAttribute('download', 'RelatorioMensalidade - ' + this.clienteSelecionado.nome + '.' + pTipoFormato.toLowerCase());
                    } else {
                        fileLink.setAttribute('download', 'RelatorioMensalidade - TODOS CLIENTES.' + pTipoFormato.toLowerCase());
                    }
                    document.body.appendChild(fileLink);
                    fileLink.click();
                }
            })
            .catch((error) => { 
                /* eslint-disable no-console */  console.log("EFETUAR LOGOFF! - gravar - " + error); 
                this.$vs.loading.close();
            }) 

        },
        gerarTodosRelatorio() {            
            this.dadosPDF = null;
            this.clienteSelecionado = null;
            this.gerarRelatorio(true);
        },
        gerarRelatorio(isTodos) {
            this.dadosPDF = null;            
            if (!isTodos && (!this.clienteSelecionado || !this.clienteSelecionado.id)) {
                this.dadosPDF = null;
                return;
            }

            this.$vs.loading();

            const params = new URLSearchParams();            
            params.append('outputFormat', 'JSON');
            if (this.clienteSelecionado && this.clienteSelecionado.id) {
                params.append('pIdCliente', this.clienteSelecionado.id);
            }            
            params.append('nomeRelatorio', "RelatorioMensalidadeClientes");
            params.append('exportarTipo', 'PDF');

            axios.post("/RelatoriosJasper", params, {              
                        credentials: 'include', responseEncoding: 'iso88591', withCredentials: true,  responseType: 'arraybuffer' } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {                
                    this.$router.push({ path: '/login' });
                } else {                                                
                    this.dadosPDF = new Uint8Array(response.data);
                    
                    if (!this.dadosPDF || this.dadosPDF.length < 2000) {
                        this.dadosPDF = null;
                    } 
                }
            })
            .catch((error) => { 
                /* eslint-disable no-console */  console.log("EFETUAR LOGOFF! - gravar - " + error); 
                this.$vs.loading.close();
            }) 
        }, 
       
    },
    mounted() {               
        this.fetchListaClientes();
    }
}
</script>

<style scoped>
  .tabelaComScroll {      
      overflow: auto;  
      height: calc(100vh - 200px);
      position: relative;      
    } 
</style>

<style lang="scss">  
.con-vs-popup {    
    .vs-popup {
      width: 800px;      
    }
}
</style>